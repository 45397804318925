import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SimpleValueAccessor, controlProviderFor } from '@mei/common/core/utils/value-accessor';

/** Password field component. */
@Component({
	standalone: true,
	selector: 'meic-password-field',
	templateUrl: './password-field.component.html',
	styleUrls: ['./password-field.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CommonModule, FormsModule, ReactiveFormsModule],
	providers: [controlProviderFor(() => PasswordFieldComponent)],
})
export class PasswordFieldComponent extends SimpleValueAccessor<string> {

	/** Whether to show password. */
	protected showPassword = false;

	/** Input placeholder. */
	@Input()
	public placeholder: HTMLInputElement['placeholder'] = '';

	/** Input autocomplete. */
	@Input()
	public autocomplete: HTMLInputElement['autocomplete'] = 'current-password';

	public constructor() {
		super();
	}

	/** Handles password toggle. */
	protected onTogglePassword(): void {
		this.showPassword = !this.showPassword;
	}
}

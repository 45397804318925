<input
	class="password-input"
	[autocomplete]="autocomplete"
	[(ngModel)]="controlValue"
	[type]="showPassword ? 'text' : 'password'"
	[placeholder]="placeholder"
/>
<button
	[ngClass]="{ show: showPassword, 'toggle-button': true }"
	(click)="onTogglePassword()"
	type="button"
></button>
